/*
// .menu
*/
@import '../variables';
@import '../mixins/hacks';
@import '../mixins/direction';


@keyframes menu__submenu--open {
    from {
        opacity: 0;
        transform: rotateY(45deg);
    }
    to {
        opacity: 1;
        transform: rotateY(0deg);
    }
}


.menu {
    color: $menu-font-color;
    background: $menu-bg;
    box-shadow: $menu-shadow;
    display: flex;
    width: 190px;
    min-width: 190px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .02em;
    font-weight: $font-weight-medium;

    // without it IE renders a blurred menu
    @include only-ie() {
        box-shadow: 0 0 0 1px rgba(#000, .15);
    }
}
.menu__list {
    list-style: none;
    padding: 10px 0;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    flex-grow: 1;
    margin: 0;
}
.menu__item {
    padding-top: 10px;
    margin-top: -10px;
    pointer-events: none;
}
.menu__item-link {
    position: relative;
    background: transparent;
    border: none;
    width: 100%;
    font-weight: inherit;
    display: block;
    padding-top: 8px;
    padding-bottom: 6px;
    color: inherit;
    pointer-events: auto;

    @include direction {
        text-align: $inline-start;
        #{$padding-inline-end}: 24px;
        #{$padding-inline-start}: 16px;
    }

    &:hover {
        color: inherit;
    }
    &:focus {
        outline: none;
    }
}
.menu__item-icon {
    position: absolute;
    top: 3px;

    @include direction {
        #{$inset-inline-start}: 15px;
    }
}
.menu__item-arrow svg {
    position: absolute;
    top: calc(50% - 5px);
    fill: $menu-item-arrow-color;

    @include direction {
        #{$inset-inline-end}: 12px;
        transform: scaleX(1 * $transform-direction);
    }
}



.menu__item--hover {
    .menu__item-link {
        background: $menu-item-hover-bg;
    }
}




// submenu
.menu__submenu {
    position: absolute;
    display: none;
    z-index: 1;

    @include direction {
        #{$inset-inline-start}: 100%;
        transform-origin: #{$inline-start};
    }
}
.menu__submenu--reverse {
    @include direction {
        #{$inset-inline-start}: auto;
        #{$inset-inline-end}: 100%;
        transform-origin: #{$inline-end};
    }
}
.menu__submenu--open {
    display: flex;
    animation-name: menu__submenu--open;
    animation-duration: .2s;
    animation-fill-mode: forwards;
}




.menu--with-icons {
    .menu__item-link {
        @include direction {
            #{$padding-inline-start}: 16px + 24px + 10px;
        }
    }
}


.menu--layout--topbar {
    width: 150px;
    min-width: 150px;
    box-shadow: $menu-topbar-shadow;

    // without it IE renders a blurred menu
    @include only-ie() {
        box-shadow: 0 0 0 1px rgba(#000, .15);
    }

    .menu__list {
        padding: 6px 0;
    }
    .menu__item-link {
        display: block;
        padding-top: 8px;
        padding-bottom: 6px;
        color: inherit;

        @include direction {
            #{$padding-inline-end}: 21px;
            #{$padding-inline-start}: 13px;
        }
    }

    &.menu--with-icons {
        .menu__item-icon {
            position: absolute;
            top: 3px;

            @include direction {
                #{$inset-inline-start}: 12px;
            }
        }
        .menu__item-link {
            @include direction {
                #{$padding-inline-start}: 13px + 24px + 8px;
            }
        }
    }
}
