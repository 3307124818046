/*
// .dropcart
*/
@import '../variables';
@import '../mixins/hacks';
@import '../mixins/direction';


$local-transition-duration: .3s;


.dropcart {
    font-size: 15px;
    font-weight: $font-weight-normal;
    color: $dropcart-font-color;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}
.dropcart__empty {
    padding: 42px 30px;
    text-align: center;
}
.dropcart__products-list {
    padding: 20px 16px;
}
.dropcart__product {
    display: flex;

    & + & {
        margin-top: 24px;
    }
}
.dropcart__product-image {
    width: 70px;
    flex-shrink: 0;
}
.dropcart__product-name {
    margin-top: -2px;
    line-height: 18px;
    font-size: 15px;

    a {
        color: inherit;
        transition: .15s;
    }
    a:hover {
        color: $link-hover-color;
    }
}
.dropcart__product-info {
    margin: 0;
    flex-grow: 1;

    @include direction {
        #{$padding-inline-end}: 10px;
        #{$padding-inline-start}: 16px;
    }
}
.dropcart__product-options {
    list-style: none;
    margin: 3px 0 0;
    padding: 0;
    font-size: 13px;
    line-height: 16px;
    color: $dropcart-font-muted-color;
}
.dropcart__product-meta {
    margin-top: 5px;
    font-size: 13px;
}
.dropcart__product-price {
    font-size: 14px;
    font-weight: $font-weight-bold;
}
.dropcart__product-remove {
    margin-top: -6px;
    flex-shrink: 0;

    @include direction {
        #{$margin-inline-end}: -6px;
    }
}
.dropcart__totals {
    border-top: 1px solid $dropcart-divider-color;
    line-height: 24px;
    padding: 16px 20px 0;

    th {
        @include direction {
            #{$padding-inline-end}: 16px;
        }
    }
    td {
        @include direction {
            text-align: $inline-end;
        }
    }

    table {
        width: 100%;
    }
}
.dropcart__buttons {
    display: flex;
    margin-top: 4px;
    padding: 20px;

    > * {
        flex-grow: 1;
    }

    > :first-child {
        @include direction {
            #{$margin-inline-end}: 8px;
        }
    }
}


.dropcart--style--dropdown {
    box-shadow: $dropcart-shadow;
    background: $dropcart-bg;
    width: 320px;

    // without it IE renders a blurred menu
    @include only-ie() {
        box-shadow: 0 0 0 1px rgba(#000, .15);
    }
}


.dropcart--style--offcanvas {
    visibility: hidden;
    transition: visibility 0s $local-transition-duration;

    .dropcart__backdrop {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $dropcart-backdrop-bg;
        opacity: 0;
        transition: opacity $local-transition-duration;
        z-index: 1000;
    }
    .dropcart__body {
        position: fixed;
        top: 0;
        z-index: 1010;
        height: 100%;
        max-height: 100%;
        background: $dropcart-bg;
        display: flex;
        flex-direction: column;
        max-width: calc(340px);
        width: calc(100% - 30px);
        transition: transform $local-transition-duration;

        @include direction {
            #{$inset-inline-start}: 100%;
        }
    }
    .dropcart__header {
        height: 55px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border-bottom: 1px solid $dropcart-divider-color;
    }
    .dropcart__title {
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin: 0 20px;
        flex-grow: 1;
    }
    .dropcart__close {
        border: none;
        padding: 0;
        margin: 0;
        width: 54px;
        height: 54px;
        background: transparent;
        fill: $dropcart-close-button-default-color;
        transition: fill .2s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: none;
        }
        &:focus,
        &:hover {
            fill: $dropcart-close-button-hover-color;
        }
    }
    .dropcart__products-list {
        flex-shrink: 1;
        flex-grow: 1;
    }
    .dropcart__totals,
    .dropcart__buttons {
        flex-shrink: 0;
    }

    &.dropcart--open {
        visibility: visible;
        transition-delay: 0s;

        .dropcart__backdrop {
            opacity: .9;
        }
        .dropcart__body {
            @include direction {
                transform: translateX(#{-100% * $transform-direction});
            }
        }
    }

    @media (min-height: 600px) {
        .dropcart__products-list {
            overflow-y: auto;
            overscroll-behavior-y: contain;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
        }
    }

    @media (max-height: 599px) {
        .dropcart__header {
            top: 0;
            position: sticky;
            background: $dropcart-bg;
            z-index: 1;
        }
        .dropcart__body {
            overflow-y: auto;
            overscroll-behavior-y: contain;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
        }
    }
}

