/*
// .block-brands
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';


.block-brands {}
.block-brands__slider {
    @include card();
}
.block-brands__item {
    padding: 32px 24px;
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .block-brands__item {
        padding: 28px 24px;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .block-brands__item {
        padding: 20px 24px;
    }
}
