/*
// .nouislider
*/
@import '../variables';
@import '../mixins/direction';


/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-target {
    position: relative;
    direction: ltr;
}
.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}
.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
    width: 0;
}
.noUi-horizontal .noUi-origin {
    height: 0;
}
.noUi-handle {
    position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 18px;
}
.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px;
}
.noUi-vertical {
    width: 18px;
}
.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    top: -17px;
}


$local-connect-height: 6px;
$local-handle-size: 16px;
$local-height: max($local-connect-height, $local-handle-size);
$local-handle-inner-size: 6px;


.noUi-target {
    height: $local-height;
}
.noUi-base {
    padding-top: ($local-height - $local-connect-height) / 2;
}
.noUi-connects {
    background: $nouislider-bg-color;
    border-radius: ($local-connect-height) / 2;
    height: $local-connect-height;
}
.noUi-connect {
    border-radius: 2px;
    background: $nouislider-connect-color;
}
.noUi-horizontal .noUi-handle {
    cursor: ew-resize;
    width: $local-handle-size;
    height: $local-handle-size;
    border-radius: $local-handle-size / 2;
    background: $nouislider-border-color;
    box-shadow: 0 0 0 0 rgba($nouislider-border-color, 0);
    transition: box-shadow .2s;

    &:after {
        display: block;
        content: '';
        position: absolute;
        width: $local-handle-inner-size;
        height: $local-handle-inner-size;
        margin: ($local-handle-size - $local-handle-inner-size) / 2;
        border-radius: $local-handle-inner-size / 2;
        background: $nouislider-handle-color;
        transition: transform .2s;
    }
}
.noUi-horizontal .noUi-handle:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($nouislider-border-color, .3);

    &:after {
        transform: scale(0);
    }
}

.noUi-horizontal .noUi-handle {
    top: ($local-height - $local-handle-size) / 2;
}

.noUi-horizontal .noUi-handle {
    @include direction {
        #{$inset-inline-start}: auto;
        #{$inset-inline-end}: (-$local-handle-size / 2);
    }
}