/*
// .reviews-view
*/
@import '../variables';
@import '../functions';


.reviews-view__header {
    margin-bottom: 50px;
}
.reviews-view__form {
    margin-top: 40px;
    border-top: 1px solid $card-divider-color;
    padding-top: 60px;
}

@media (max-width: breakpoint(xs-end)) {
    .reviews-view__header {
        margin-bottom: 32px;
    }
    .reviews-view__form {
        margin-top: 30px;
        padding-top: 50px;
    }
}
