@use '@angular/material' as mat;

@include mat.core();

$stroyka-primary: mat.define-palette(mat.$indigo-palette, 500);
$stroyka-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$stroyka-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$stroyka-theme: mat.define-light-theme((
  color: (
    primary: $stroyka-primary,
    accent: $stroyka-accent,
    warn: $stroyka-warn,
    ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.core-theme($stroyka-theme);

@include mat.button-theme($stroyka-theme);

@import '../node_modules/ngx-toastr/toastr.css';
@import 'scss/style';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

body:not(.style-scope):not([no-y-overflow]):not(.style-scope)::-webkit-scrollbar {
  width: 16px;
}

body:not(.style-scope):not([no-y-overflow]):not(.style-scope)::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #909090;
}