/*
// .product
*/
@import '../variables';
@import '../functions';
@import '../mixins/hacks';
@import '../mixins/direction';


.product {}
.product__content {
    display:-ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
    grid-template-rows: auto auto auto auto auto;
    grid-column-gap: 40px;
}
.product__gallery {
    -ms-grid-row: 1;
    -ms-grid-row-span: 6;
    -ms-grid-column: 1;

    @include only-ie() {
        @include direction {
            #{$margin-inline-end}: 40px;
        }
    }

    grid-row-start: 1;
    grid-row-end: 6;
    min-height: 0;
}
.product__info {
    -ms-grid-row: 1;
    -ms-grid-column: 2;

    position: relative;
    min-height: 0;
}
.product__sidebar {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.product__footer {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.product__wishlist-compare {
    display: none;
}
.product__name {
    margin-bottom: 22px;
}
.product__rating {
    display: flex;
    margin-bottom: 5px;
}
.product__rating-stars {
    padding-top: 2px;

    @include direction {
        #{$margin-inline-end}: 12px;
    }
}
.product__rating-legend {
    font-size: 14px;
    line-height: 20px;
    color: $rating-legend-font-color;

    a {
        color: inherit;
        transition: color .2s;
    }
    a:hover {
        color: $link-hover-color;
    }

    span {
        content: '/';
        padding: 0 7px;
    }
}
.product__description {
    font-size: 16px;
}
.product__features {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;

    li {
        padding-top: 1px;
        padding-bottom: 1px;
        position: relative;

        @include direction {
            #{$padding-inline-start}: 13px;
            #{$padding-inline-end}: 0;
        }
    }
    li::before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        border: 1px solid currentColor;

        @include direction {
            #{$inset-inline-start}: 0;
        }
    }
}
.product__meta {
    list-style: none;
    margin: 12px 0 0;
    padding: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
    color: $body-font-muted-color;
    font-size: 14px;
    border-top: 1px solid $body-divider-color;

    li {
        @include direction {
            #{$margin-inline-end}: 18px;
        }
    }

    a {
        color: inherit;
        transition: color .2s;
    }
    a:hover {
        color: $link-hover-color;
    }
}
.product__availability {
    display: none;
}
.product__prices {
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 28px;
    font-weight: $font-weight-bold;
    letter-spacing: -.03em;
    color: $price-color;
}
.product__new-price {
    color: $price-new-color;
}
.product__old-price {
    color: $price-old-color;
    font-weight: $font-weight-normal;
    font-size: 20px;
    text-decoration: line-through;
}
.product__option {
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 0;
    }
}
.product__option-label {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $body-font-alt-color;
    padding-bottom: 2px;
}
.product__actions {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
}
.product__actions-item {
    margin: 4px;
}
.product__quantity {
    width: 120px;
}
.product__footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
}
.product__share-buttons {
    margin-top: 2px;
    margin-bottom: 2px;

    @include direction {
        #{$margin-inline-start}: 24px;
        #{$margin-inline-end}: 0;
    }
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .product__name {
        font-size: 24px;
        margin-bottom: 18px;
    }
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .product__content {
        -ms-grid-columns: 44% 56%;
        grid-template-columns: [gallery] calc(44% - 15px) [info] calc(56% - 15px);
        grid-column-gap: 30px;
    }
    .product__gallery {
        @include only-ie() {
            @include direction {
                #{$margin-inline-end}: 30px;
            }
        }
    }
    .product__name {
        font-size: 24px;
        margin-bottom: 18px;
    }
    .product__footer {
        display: block;
        margin-top: 18px;
    }
    .product__share-buttons {
        margin: 12px 0 0;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .product__content {
        display: block;
    }
    .product__gallery {
        margin-bottom: 24px;
    }
    .product__name {
        font-size: 24px;
        margin-bottom: 18px;
    }
}
@media (max-width: 559px) {
    .product__footer {
        display: block;
        margin-top: 24px;
    }
    .product__share-buttons {
        margin: 12px 0 0;
    }
}


@import 'product--layout--columnar';


.product--layout--sidebar {
    @media (min-width: breakpoint(lg-start)) {
        .product__content {
            -ms-grid-columns: 50% 50%;
            grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
            grid-column-gap: 32px;
        }
        .product__gallery {
            @include only-ie() {
                @include direction {
                    #{$margin-inline-end}: 32px;
                }
            }
        }
        .product__name {
            font-size: 24px;
            margin-bottom: 12px;
        }
        .product__footer {
            display: block;
            margin-top: 18px;
        }
        .product__share-buttons {
            margin: 12px 0 0;
        }
    }
}


.product--layout--quickview {
    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .product__content {
            grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
            grid-column-gap: 32px;
        }
        .product__name {
            margin-bottom: 12px;
        }
        .product__footer {
            display: block;
            margin-top: 18px;
        }
        .product__share-buttons {
            margin: 12px 0 0;
        }
    }
    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .product__content {
            display: block;
        }
        .product__gallery {
            margin-bottom: 24px;
        }
        .product__name {
            font-size: 24px;
            margin-bottom: 18px;
        }
    }
}
