/*
// .modal
*/
@import '../variables';


.modal {
    -webkit-overflow-scrolling: touch;
}
.modal-content {
    border: none;
    border-radius: $modal-border-radius;
    box-shadow: $modal-shadow;
}