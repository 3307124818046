/*
// .share-buttons
*/
@import '../variables';
@import '../mixins/direction';


.share-buttons {}
.share-buttons__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 0 -4px;
    font-size: 11px;
    line-height: 18px;
    font-weight: $font-weight-medium;
    white-space: nowrap;

    @include direction {
        #{$margin-inline-end}: -4px;
    }
}
.share-buttons__item {
    margin-bottom: 4px;

    @include direction {
        #{$margin-inline-end}: 4px;
    }
}
.share-buttons__item-icon + .share-buttons__item-label {
    @include direction {
        #{$margin-inline-start}: 5px;
    }
}
.share-buttons__item {
    a {
        background: $share-buttons-bg-color;
        color: $share-buttons-font-color;
        border-radius: 1.5px;
        display: block;
        padding: 2px 6px 1px 6px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        transition: box-shadow .12s ease-in-out;

        &, &:hover {
            text-decoration: none;
        }

        &:before {
            z-index: -1;
            display: block;
            content: '';
            position: absolute;
            left: 0; // SKIP-RTL
            top: 0;
            right: 0; // SKIP-RTL
            bottom: 0;
            background: #000;
            opacity: 0;
            transition: opacity .08s ease-in-out;
        }
    }
    a:hover:before {
        opacity: .12;
    }
    a:active {
        box-shadow: inset 0 1px 3px rgba(#000, .35);
        transition-duration: .04s;

        &:before {
            opacity: .06;
            transition-duration: .04s;
        }
    }
}
.share-buttons__item--without-label {
    a {
        width: 21px;
        text-align: center;
        padding: 2px 0 1px 0;
    }
}


@each $type, $link in $share-buttons {
    .share-buttons__item--type--#{$type} a {
        @if (map_get($link, bg-color) != $share-buttons-bg-color) {
            background: map_get($link, bg-color);
        }
        @if (map_get($link, font-color) != $share-buttons-font-color) {
            &,
            &:hover {
                color: map_get($link, font-color);
            }
        }
    }
}



